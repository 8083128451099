<template>
    <div class="user-block-comments" v-if="noComments">
        <slot v-if="widgetLoad">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </slot>
        <slot v-else>
            <ul class="user-block-comment mb-0" v-if="comments.length">
                <li class="user-block-comment-item" v-for="comment in comments" :key="comment.id">
                    <CommentItem :comments="comments" :comment="comment" :subject="newPost.subject"></CommentItem>
                </li>
                <div v-if="pagination.last_page > 1" class="p-3 d-flex justify-content-center pagination-box-items">
                    <Paginate :page-count="pagination.last_page" :click-handler="loadData" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></Paginate>
                </div>
            </ul>
        </slot>
    </div>
    </template>
    
    <style lang="scss">
        .user-block-comment-items ul li.like {
            position: relative;
            top: 3px;
            float: none;
        }
    
        .user-block-comment-item-content .emoji {
            width: 17px !important;
            margin-right: 3px !important;
        }
    
        .user-block-comment-item-content .post-img {
            max-width: 100%;
            margin-top: 20px;
        }
    </style>
    
    <script>
    // این کامپوننت برای نمایش ساب کامنت های نظرات می باشد
    // وضعیت فعلی : غیرفعال می باشد
    import moment from "moment-jalaali";
    // import PostSend from '@/components/TgjuPosts/PostSend.vue'
    // import PostItem from '@/components/TgjuPosts/PostItem.vue'
    import CommentItem from '@/components/TgjuPosts/CommentItem.vue'
    import Paginate from "vuejs-paginate";
    
    export default {
        name: 'TgjuPostCommentsModal',
        props: ['data', 'parent_comments'],
        components: {
            // PostSend,
            // PostItem,
            CommentItem,
            Paginate,
        },
        data: function () {
            return {
                newPost: {
                    content: '',
                    type: null,
                    subject: null,
                    attachments: [],
                    parent_id: null,
                    parent: null,
                },
                post: null,
                pagination: {
                    length: undefined,
                    current_page: undefined,
                    from: undefined,
                    last_page: undefined,
                    next_page_url: undefined,
                    per_page: undefined,
                    prev_page_url: undefined,
                    to: undefined,
                    total: undefined,
                },
                user: this.$helpers.userSync(),
                comments: [],
                widgetLoad: false,
                noComments: true
            }
        },
        // computed: {
        // 	posts: {
        // 		get() {
        // 		return this.data.posts.data;
        // 		},
        // 		set(value) {
        // 		this.data.posts.data = value;
        // 		}
        // 	},
    
        // 	comments: {
        // 		get() {
        // 		return this.data.comments;
        // 		},
        // 		set(value) {
        // 		this.data.comments = value;
        // 		}
        // 	}
        // },
        mounted () {
            if (this.parent_comments.data) {
                let comments = [];
        
                this.parent_comments.data.forEach(comment => {
                    comments.push(comment);
                });
        
                this.comments = comments;
        
                if (comments.length <= 0) {
                    this.noComments = false;
                }
                this.pagination = this.data.post.comments;
                this.widgetLoad = false;
            }
        },
        watch: {
            data: {
                handler: function (newValue, oldValue) {
                    if (Object.keys(newValue).length) {
                        this.post = this.data.post;
                        let comments = [];
                        if (newValue.comments != 0) {
                            newValue.comments.data.forEach(comment => {
                                comments.push(comment);
                            });
    
                            this.comments = comments;
                            if (comments.length <= 0) {
                                this.noComments = false;
                            }
                            this.widgetLoad = false;
                        }
    
                        // this.newPost.type = 'tgju-comment';
                        // this.newPost.parent_id = this.post.id;
                        // this.newPost.parent = this.post;
                        // this.newPost.subject = this.data.subject;
    
                        if (typeof oldValue == 'undefined' || !Object.keys(oldValue).length || this.data.post.id != oldValue.post.id) {
                            // this.loadData();
    
                        }
                    }
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            // این متد یک سری داده های اولیه را به کامپوننت تزریق می کند
            loadData(page) {
                let api_data = {
                    parent_id: this.post.id,
                    page: page ? page : 1,
                };
    
                this.$helpers.makeRequest('GET', '/tgju-post/comments', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);
    
                    if (api_response.status == 200) {
                        let response = api_response.data.response;
                        let comments = [];
    
                        this.pagination = api_response.data.response.comments;
    
                        response.comments.data.forEach(comment => {
                            comments.push(comment);
                        });
    
                        this.comments = comments;
    
                        if (comments.length <= 0) {
                            this.noComments = false;
                        }
    
                        // this.$parent.emitData('loadComments', {comments: this.$helpers.unbindObject(this.comments)});
                        this.widgetLoad = false;
                    }
                });
            },
            // این متد برای هدایت کاربر به بخش ارسال ساب کامنت یا ارسال پاسخ برای یک کامنتی اشتفاده می شود
            askParentToSetReply(data) {
                let parent = this.$helpers.getParent(this);
                parent.setReply(data);
            },
            //این متد برای هدایت کاربر به بخش ویرایش یک کامنت استفاده میشود
            askParentToEditComment(data) {
                let parent = this.$helpers.getParent(this);
                parent.editComment(data);
            },
            
        },
    }
    </script>
    